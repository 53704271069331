import revive_payload_client_oED04eYmny from "/usr/local/app/node_modules/.pnpm/nuxt@3.12.4_eslint@9.9.0_rollup@4.21.0_typescript@5.5.4_vite@5.4.1/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_lhRp8rV52d from "/usr/local/app/node_modules/.pnpm/nuxt@3.12.4_eslint@9.9.0_rollup@4.21.0_typescript@5.5.4_vite@5.4.1/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_JdLoPUXoeG from "/usr/local/app/node_modules/.pnpm/nuxt@3.12.4_eslint@9.9.0_rollup@4.21.0_typescript@5.5.4_vite@5.4.1/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_PSVrL3Z4IE from "/usr/local/app/node_modules/.pnpm/nuxt@3.12.4_eslint@9.9.0_rollup@4.21.0_typescript@5.5.4_vite@5.4.1/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_FtCOSbRVgY from "/usr/local/app/node_modules/.pnpm/nuxt@3.12.4_eslint@9.9.0_rollup@4.21.0_typescript@5.5.4_vite@5.4.1/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_1ia9TSDZu5 from "/usr/local/app/node_modules/.pnpm/nuxt@3.12.4_eslint@9.9.0_rollup@4.21.0_typescript@5.5.4_vite@5.4.1/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_AC2vzxNDrf from "/usr/local/app/node_modules/.pnpm/nuxt@3.12.4_eslint@9.9.0_rollup@4.21.0_typescript@5.5.4_vite@5.4.1/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/usr/local/app/.nuxt/components.plugin.mjs";
import prefetch_client_lNBfrdSKoz from "/usr/local/app/node_modules/.pnpm/nuxt@3.12.4_eslint@9.9.0_rollup@4.21.0_typescript@5.5.4_vite@5.4.1/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_xkGf2LM3JS from "/usr/local/app/node_modules/.pnpm/@nuxtjs+color-mode@3.4.4_rollup@4.21.0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import documentDriven_r7gwne8TkP from "/usr/local/app/node_modules/.pnpm/@nuxt+content@2.13.2_nuxt@3.12.4_rollup@4.21.0_vue@3.4.38/node_modules/@nuxt/content/dist/runtime/legacy/plugins/documentDriven.js";
import nuxt_plugin_Mp9QcMIJTS from "/usr/local/app/node_modules/.pnpm/nuxt-delay-hydration@1.3.6_rollup@4.21.0/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import provideState_mNluVDG2ET from "/usr/local/app/node_modules/.pnpm/nuxt-graphql-middleware@4.0.0_graphql-tag@2.12.6_graphql@16.9.0_nuxt@3.12.4_rollup@4.21.0_typescript@5.5.4_vite@5.4.1/node_modules/nuxt-graphql-middleware/dist/runtime/plugins/provideState.mjs";
import plugin_uNYPflKUpC from "/usr/local/app/node_modules/.pnpm/@nuxtjs+device@3.1.1_rollup@4.21.0/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import i18n_pPgjKLMFme from "/usr/local/app/node_modules/.pnpm/@nuxtjs+i18n@8.3.1_rollup@4.21.0_vue@3.4.38/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_j09xf8zqO1 from "/usr/local/app/node_modules/.pnpm/@nuxt+image@1.7.0_rollup@4.21.0/node_modules/@nuxt/image/dist/runtime/plugin.mjs";
import unocss_MzCDxu9LMj from "/usr/local/app/.nuxt/unocss.mjs";
import plugin_HMUycbzStU from "/usr/local/app/modules/pino/runtime/plugin.ts";
import plugin_xDtleEcACi from "/usr/local/app/modules/gtm/runtime/plugin.ts";
import _0_graphqlConfig_yaUZblG674 from "/usr/local/app/plugins/0.graphqlConfig.ts";
import _1_auth_slaK5pfDbE from "/usr/local/app/plugins/1.auth.ts";
import _2_activeOrder_yYINElYs5H from "/usr/local/app/plugins/2.activeOrder.ts";
import collections_pRcEP3swaI from "/usr/local/app/plugins/collections.ts";
import foxentry_client_gcy3vwc779 from "/usr/local/app/plugins/foxentry.client.ts";
import scroll_client_HJUo2UKx9n from "/usr/local/app/plugins/scroll.client.ts";
import supportAgent_3y6YttNG9k from "/usr/local/app/plugins/supportAgent.ts";
import vee_validate_KcKlKmvCrJ from "/usr/local/app/plugins/vee-validate.ts";
export default [
  revive_payload_client_oED04eYmny,
  unhead_lhRp8rV52d,
  router_JdLoPUXoeG,
  payload_client_PSVrL3Z4IE,
  navigation_repaint_client_FtCOSbRVgY,
  check_outdated_build_client_1ia9TSDZu5,
  chunk_reload_client_AC2vzxNDrf,
  components_plugin_KR1HBZs4kY,
  prefetch_client_lNBfrdSKoz,
  plugin_client_xkGf2LM3JS,
  documentDriven_r7gwne8TkP,
  nuxt_plugin_Mp9QcMIJTS,
  provideState_mNluVDG2ET,
  plugin_uNYPflKUpC,
  i18n_pPgjKLMFme,
  plugin_j09xf8zqO1,
  unocss_MzCDxu9LMj,
  plugin_HMUycbzStU,
  plugin_xDtleEcACi,
  _0_graphqlConfig_yaUZblG674,
  _1_auth_slaK5pfDbE,
  _2_activeOrder_yYINElYs5H,
  collections_pRcEP3swaI,
  foxentry_client_gcy3vwc779,
  scroll_client_HJUo2UKx9n,
  supportAgent_3y6YttNG9k,
  vee_validate_KcKlKmvCrJ
]